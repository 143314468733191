(function($) {

  // Fixed Main Menu
  Drupal.behaviors.fixedMenu = {
    attach: function() {
      $(document).scroll(function(){
        if ($(this).scrollTop() > 20) {
          $('.l-header--wrapper').addClass('fixed');
        } else {
          $('.l-header--wrapper').removeClass('fixed');
        }
      });
    }
  };

  // Back To Top Button
  Drupal.behaviors.backToTop = {
    attach: function() {
      $(document).scroll(function() {
        if (($(this).scrollTop() + $(window).height() / 2) > $(this).height() / 2) {
          $('#topScroll').fadeIn('slow');
        }
        else {
          $('#topScroll').fadeOut('slow');
        }
      });

      $('#topScroll').click(function(e){
        $('html, body').animate({scrollTop: 0}, 500);
        e.preventDefault();
        return false;
      });
    }
  };

})(jQuery);
